import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { loginUser, userSelector } from "./UserSlice";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { isFetching, isSuccess, isError, errorMessage  } = useSelector(userSelector);
  const [isMobile, setIsMobile] = useState(false);
  const onSubmit = (data) => {
    dispatch(loginUser(data));
  };

  useEffect(() => {
    window.innerWidth < 567 ? setIsMobile(true) : setIsMobile(false);
    if (isSuccess) {
      navigate("/");
    }
  }, [errorMessage, isSuccess, isError, navigate]);

  function detectWindowSize() {
    window.innerWidth < 567 ? setIsMobile(true) : setIsMobile(false);        
  }
  window.onresize = detectWindowSize;

  if (isMobile) {
    return (
      <div className="min-h-screen gray-bg  flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <img src={logo} alt="logo" className="max-w-min mx-auto" />
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-thin  text-gray-500">
            Rotate the screen
          </h2>
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      <div className="min-h-screen gray-bg  flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <img src={logo} alt="logo" className="max-w-min mx-auto" />
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-thin  text-gray-500">
            Welcome to the 
            <br/>USJA Membership Lookup System
          </h2>
        </div>
        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
          <div className=" py-4 px-4 sm:px-10">
            <form
              className="space-y-4"
              onSubmit={handleSubmit(onSubmit)}
              method="POST"
            >
              {isError && errorMessage ? (
                  <div
                    className="capitalize text-sm text-red-700 "
                    role="alert"
                  >
                    <span className="font-medium"></span>Login Failed.
                  </div>
                ) : isSuccess ? (
                  <div className="mt-4 text-sm text-green-700" role="alert">
                    <span className="font-medium">Successfully</span> Login
                  </div>
                ) : (
                  ""
                )}
              <div>
                <label htmlFor="name" className="sr-only">
                  Username
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    // min={4}
                    // max={64}
                    autoComplete="email"
                    // ref={register({
                    //   pattern: /^[a-zA-Z0-9+_.-]/i,
                    // })}
                    {...register("name", { minLength: 4, maxLength: 64 })}
                    placeholder="Username"
                    required
                    className="appearance-none out-of-range:border-red-500  block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
                {errors.name ? (
                  <div
                    className="p-1 mb-1 normal-case text-sm text-red-700 "
                    role="alert"
                  >
                    Username must be in range 4-64 letters
                    <span className="font-medium"></span>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <div className="mt-0">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    {...register("password", { required: true })}
                    autoComplete="current-password"
                    placeholder="Password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
                
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  {isFetching ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : null}
                  Log in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
