import React, { Fragment, useState, useEffect } from "react";
import { clearUserState, logoutUser, userSelector } from "./UserSlice";
import { useSelector, useDispatch } from "react-redux";
import * as Loader from "react-loader-spinner";
import { memberUser, memberSelector, clearMemberState } from "./memberSlice";
import { rankUser, rankSelector,clearRankState } from "./rankSlice";
import { certificatesUser, certificatesSelector, clearState } from "./certificatesSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";

const $ = require("jquery");
$.DataTable = require("datatables.net");
const Dashboard = () => {
  const dispatch = useDispatch();
  const table2 = $("#table2").DataTable();
  const table1 = $("#table1").DataTable();
  const [memberid, setMemberid] = useState();
  const [birthday, setBirthday] = useState();
  const [lastname, setLastname] = useState();
  let [hidden, setHidden] = useState(true);
  let [hidden2, setHidden2] = useState(true);
  const { isFetching } = useSelector(userSelector);
  const { Success, Error, member_id, name, dues_paid_until, errMessage} = useSelector(memberSelector);
  const { data} = useSelector(rankSelector);
  const { certificates } = useSelector(certificatesSelector);
  const token1 = localStorage.getItem("token");
  const [isMobile, setIsMobile] = useState(false);
  const [appendMargin, setAppendMargin] = useState(false);
  
  const handleRank = (el) => {
    if(!Error){
    $(el).DataTable({
      data: data,
      columns: [
        { title: "Style" },
        { title: "Rank" },
        { title: "Date of Rank" },
      ],
      bDestroy: true,
      destroy: true,
      // responsive: true,  
      lengthChange: false,
      pageLength: 100,
      
    });}
  };
  const handleCertificates = (el) => {
    if(!Error)
     { $(el).DataTable({
        data: certificates,
        columns: [
          { title: "Style" },
          { title: "Issued" },
          { title: "Certification" },
          { title: "Expire" },
          { title: "Status" },
        ],
        bDestroy: true,
        // responsive: true,
        lengthChange: false,
        pageLength: 100,
        columnDefs: [
          {
              target: 0,
              visible: false,
              searchable: false,
          },
      ],
        
      });
    }
  
  };
  const sender =  () => {
    dispatch(memberUser({
      token           : token1,
      member_id       : memberid,
      lastname        : lastname,
      birthday        : birthday,
    }));
  };
  const clearStates =()=>{
    dispatch(clearMemberState())
    dispatch(clearUserState())
    dispatch(clearRankState())
    dispatch(clearState())
  }
  const clearTable= ()=>{
    dispatch(clearState());
    dispatch(clearRankState());
    table1.clear().draw();
    table2.clear().draw();
  }
  
  useEffect(() => {
    window.innerWidth < 897 ? setAppendMargin(true) : setAppendMargin(false);
    window.innerWidth < 567 ? setIsMobile(true) : setIsMobile(false);
    if(Success){
      dispatch(rankUser({ token: token1, member_id: member_id }));
      dispatch(certificatesUser({ token: token1, member_id: member_id }));
    }
    else if(Error){
      clearTable();
    }
  }, [token1, Success, member_id])

  function detectWindowSize() {
    window.innerWidth < 567 ? setIsMobile(true) : setIsMobile(false);
    window.innerWidth < 897 ? setAppendMargin(true) : setAppendMargin(false);        
  }
  window.onresize = detectWindowSize;
  
  const Collapse1 = () => {
    setHidden((s) => !s);
  };
  const Collapse2 = () => {
    setHidden2((s1) => !s1);
  };

  if (isMobile) {
    return (
      <div className="min-h-screen gray-bg  flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <img src={logo} alt="logo" className="max-w-min mx-auto" />
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-thin  text-gray-500">
            Rotate the screen
          </h2>
        </div>
      </div>
    )
  }

  return (
    <div className=" min-h-screen overflow-y-auto gray-bg">
    <div className="container mx-auto ">
      {isFetching ? (
        <Loader.TailSpin type="Puff" color="#00BFFF" height={100} width={100} />
      ) : (
        <Fragment>
          <div className=" flex-col  items-center justify-center">
            <div className=" py-4">
              <div className="mb-3  flex-row flex justify-between items-center xs:flex-col xs:justify-between xs:items-center">
                <div className="flex items-center">
                <img src={logo} alt="logo" className="w-24" />
                <h2 className="text-gray-600 mx-4 font-bold text-base text-center ">UNITED STATES <br/>JUDO ASSOCIATION</h2>
                </div>
                <div className="input-group relative flex w-2/4 xs:mt-4 xs:w-[unset]  ">
                  <div style={{display: 'flex', alignItems: 'center'}}>
                  <input
                    id="member_number"
                    type="search"
                    onChange={(e) => {
                      setMemberid(e.target.value)
                    }}
                    onFocus={() => {
                        setLastname('')
                        $('#lastname').val('')
                        setBirthday('')
                        $('#birthday').val('')
                    }}
                    className="form-control relative flex-auto min-w-0 block w-full px-3 py-2.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Search by member number"
                    aria-label="Search"
                    aria-describedby="button-addon2"
                  /></div>
                  <div style={{padding: '1em', display: 'flex', alignItems: 'center'}}>or</div>
                  <p>
                  <input
                    id="lastname"
                    type="text"
                    onChange={(e) => {
                      setLastname(e.target.value)
                    }}
                    onFocus={() => {
                        setMemberid('')
                        $('#member_number').val('')
                    }}
                    className="form-control relative flex-auto min-w-0 block w-full px-3 py-2.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Last name"
                    aria-label="Search"
                    aria-describedby="button-addon2"
                  />
                  <input
                    id="birthday"
                    type="date"
                    onChange={(e) => {
                      setBirthday(e.target.value)
                    }}
                    onFocus={() => {
                        setMemberid('')
                        $('#member_number').val('')
                    }}
                    className="form-control relative flex-auto min-w-0 block w-full px-3 py-2.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Search by birthday"
                    aria-label="Search"
                    aria-describedby="button-addon2"
                  />
                  </p>
                  <button
                    className=" px-6 py-2.5  font-medium text-xs leading-tight  rounded shadow-md  hover:shadow-lg  focus:outline-none focus:ring-0  btn"
                    type="button"
                    id="button-addon2"
                    onClick={() => sender()}
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="search"
                      className="w-4"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                      ></path>
                    </svg>
                  </button>
                  
                </div>
                <Link to={'/login'} style = {{marginLeft: appendMargin ? '10px' : 'none'}}><button
                    className=" inline-block px-6 py-2.5 xs:mt-4 lg:mt-0  font-medium text-sm leading-tight  rounded shadow-md  hover:shadow-lg  focus:outline-none focus:ring-0  btn "
                    type="button"
                    onClick={() => logoutUser()}
                  >Logout</button></Link>
                
              </div>
              {Error
                  ? ( <div className="font-medium text-red-700 " role="alert">
                        Sorry, we can't find that member
                      </div>)
                  : name
                    ? ( <div className="text-gray-600 font-medium">
                          <table><tbody>
                          <tr>
                            <td style={{width: '50%'}} className="text-right">Name: </td>
                            <td>{name}</td>
                          </tr>
                          <tr>
                            <td className="text-right">Member Number: </td>
                            <td>{member_id}</td>
                          </tr>
                          <tr>
                            <td className="text-right">Dues paid until: </td>
                            <td>{dues_paid_until}</td>
                          </tr>
                          </tbody></table>
                        </div>)
                    : ""}
            </div>
            <div className="w-11/12 mx-auto">
            <div className={"ibox float-e-margins" + (appendMargin ? "" : " mx-24")}>
              <div className="flex justify-between">
                <div className="ibox-title">
                  <h5 className="font-semibold">Ranks</h5>
                </div>

                <button
                  type="button"
                  onClick={() => Collapse1()}
                  className="border-none px-4 py-2  text-sm font-medium text-white "
                  id="menu-button"
                  aria-expanded="true"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon icon={faChevronUp} />
                </button>
              </div>
              {hidden ? (
                <div className="ibox-content1 ">
                  <table
                  id="table1"
                    className="display responsive cell-border hover stripe "
                    width={"100%"}
                    ref={(e) => handleRank(e)}
                  ></table>
                </div>
              ) : (
                <p></p>
              )}
            </div>
            <div className={"ibox float-e-margins" + (appendMargin ? "" : " mx-24")}>
              <div className="flex justify-between">
                <div className="ibox-title">
                  <h5 className="font-semibold">Certifications</h5>
                </div>

                <button
                  type="button"
                  onClick={() => Collapse2()}
                  className="border-none px-4 py-2  text-sm font-medium text-white "
                  id="menu-button"
                  aria-expanded="true"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon icon={faChevronUp} />
                </button>
              </div>
              {hidden2 ? (
                <div className="ibox-content2 ">
                  <table
                  id="table2"
                    className="display cell-border hover stripe"
                    width={"100%"}
                    ref={(e) => handleCertificates(e)}
                  ></table>
                </div>
              ) : (
                ""
              )}
            </div>

            </div>
          </div>
        </Fragment>
      )}
    </div>
    </div>
  );
};

export default Dashboard;
