import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from "../../helpers/constants";

export const rankUser = createAsyncThunk(
  'member_rank',
  async ( {token , member_id }, thunkAPI) => {
    try {
      const response = await fetch(
        `${BASE_URL}/lookup/member_ranks`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `token=${token}&member_id=${member_id}`,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        if(data.errors)
        {
          return thunkAPI.rejectWithValue(data.errors[0].code);
        }
        else{
          return data;
        }
    
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue("Server Error");
    }
  }
);


export const rankSlice = createSlice({
  name: 'member_rank',
  initialState: {
    data: '',
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearRankState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.data = "";

      return state;
    },
  },
  extraReducers: {
    [rankUser.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      return state;
    },
    [rankUser.rejected]: (state, { payload }) => {
      state.data = payload.data;
      state.errorMessage =  payload
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
    },
    [rankUser.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearRankState } = rankSlice.actions;

export const rankSelector = (state) => state.rank;
