import React from 'react';
import './App.css';

import { BrowserRouter as Router, Routes ,Route } from 'react-router-dom';
import Login from './features/User/Login';
import Dashboard from './features/User/Dashboard';
import { PrivateRoute } from './helpers/PrivateRoute';

function App() {
  return (
    <div className="App ">
      <Router>
        <Routes>
          <Route exact element={<Login/>} path="/login" />
          <Route exact element={
          <PrivateRoute>
            <Dashboard/>
          </PrivateRoute>
          }
          path='/'/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
