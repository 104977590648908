import { configureStore } from '@reduxjs/toolkit';
import { certificatesSlice } from '../features/User/certificatesSlice';
import { memberSlice } from '../features/User/memberSlice';
import { rankSlice } from '../features/User/rankSlice';
import { userSlice } from '../features/User/UserSlice';
export default configureStore({
  reducer: {
    user: userSlice.reducer,
    member: memberSlice.reducer,
    rank: rankSlice.reducer,
    certificates: certificatesSlice.reducer
  },
});
