import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from "../../helpers/constants";

export const certificatesUser = createAsyncThunk(
  'memder_certificates',
  async ( {token , member_id }, thunkAPI) => {
    try {
      const response = await fetch(
        `${BASE_URL}/lookup/member_certifications`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `token=${token}&member_id=${member_id}`,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        if(data.errors)
        {
          return thunkAPI.rejectWithValue(data.errors[0].code);
        }
        else{
          return data;
        }
    
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue("Server Error");
    }
  }
);


export const certificatesSlice = createSlice({
  name: 'member_certificates',
  initialState: {
    certificates: '',
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.certificates = '';

      return state;
    },
  },
  extraReducers: {
    [certificatesUser.fulfilled]: (state, { payload }) => {
      state.certificates = payload.data;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      return state;
    },
    [certificatesUser.rejected]: (state, { payload }) => {
      state.certificates = "";
      state.errorMessage =  payload
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
    },
    [certificatesUser.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearState } = certificatesSlice.actions;

export const certificatesSelector = (state) => state.certificates;
