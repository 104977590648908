import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate} from 'react-router-dom';
import { memberSelector, clearMemberState } from '../features/User/memberSlice';
import { clearUserState } from '../features/User/UserSlice';
import { clearState } from '../features/User/certificatesSlice';
import { clearRankState } from '../features/User/rankSlice';


export function PrivateRoute({ children }) {
  const dispatch = useDispatch()
  const clearStates =()=>{
      dispatch(clearMemberState())
      dispatch(clearUserState())
      dispatch(clearRankState())
      dispatch(clearState())
}
  const {errMessage} = useSelector(memberSelector)
    if (errMessage==='invalid_credentials'  || errMessage==='Server Error') {
      clearStates();
    }

   if (!localStorage.getItem('token')) {
    clearStates();
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login"  replace />;
  }
  
  return children;
}
