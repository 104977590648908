import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from "../../helpers/constants";

export const memberUser = createAsyncThunk(
  'member_id',
  async ( {token , member_id, lastname, birthday}, thunkAPI) => {
    try {
      const response = await fetch(
        `${BASE_URL}/lookup/search_member`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `token=${token}&member_id=${member_id}&lastname=${lastname}&birthday=${birthday}`,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        if(data.errors)
        {
          return thunkAPI.rejectWithValue(data.errors[0].code);
        }
        else{
          return data;
        }
    
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue("Server Error");
    }
  }
);


export const memberSlice = createSlice({
  name: 'member_id',
  initialState: {
    member_id: '',
    name: '',
    dues_paid_until: '',
    Fetch: false,
    Success: false,
    Error: false,
    errMessage: '',
  },
  reducers: {
    clearMemberState: (state) => {
      state.name = "";
      state.member_id = "";
      state.dues_paid_until = "";
      state.Error = false;
      state.Success = false;
      state.Fetch = false;
      state.errMessage=""
      return state;
    },
  },
  extraReducers: {
    [memberUser.fulfilled]: (state, { payload }) => {
      state.name = payload.data[0].name;
      state.member_id = payload.data[0].member_id;
      state.dues_paid_until = payload.data[0].dues_paid_until;
      state.Fetch = false;
      state.Success = true;
      state.Error = false;
      state.errMessage=""

      return state;
    },
    [memberUser.rejected]: (state, { payload }) => {
      state.name = '';
      state.member_id = '';
      state.dues_paid_until = '';
      state.errMessage =  payload
      state.Fetch = false;
      state.Error = true;
      state.Success = false;
      return state;

    },
    [memberUser.pending]: (state) => {
      state.Fetch = true;
    },
  },
});

export const { clearMemberState } = memberSlice.actions;

export const memberSelector = (state) => state.member;
